/* @import url('https://db.onlinewebfonts.com/c/1ccdd11fd9d1d81756c40d7acb17d0aa?family=Circular+Spotify+Tx+T+Light'); */

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Londrina+Outline&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Syncopate:wght@400;700&display=swap');

@font-face {font-family: "Circular Spotify Tx T Light";
  src: url("http://db.onlinewebfonts.com/t/1ccdd11fd9d1d81756c40d7acb17d0aa.eot"); /* IE9*/
  src: url("http://db.onlinewebfonts.com/t/1ccdd11fd9d1d81756c40d7acb17d0aa.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("http://db.onlinewebfonts.com/t/1ccdd11fd9d1d81756c40d7acb17d0aa.woff2") format("woff2"), /* chrome firefox */
  url("http://db.onlinewebfonts.com/t/1ccdd11fd9d1d81756c40d7acb17d0aa.woff") format("woff"), /* chrome firefox */
  url("http://db.onlinewebfonts.com/t/1ccdd11fd9d1d81756c40d7acb17d0aa.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("http://db.onlinewebfonts.com/t/1ccdd11fd9d1d81756c40d7acb17d0aa.svg#Circular Spotify Tx T Light") format("svg"); /* iOS 4.1- */
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}

@keyframes move {
  from,
  to {
    left: -80%;
    top: -10%;
  }
  50% {
    left: 80%;
    top: 40%;
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #1AE400; 
  border-radius: 5px;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Circular Spotify Tx T Light", ROBOTO, Haas;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; Hide scrollbars */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.home-page-container {
  height: auto;
  width: auto;
  /* position: absolute; */
}
.outer {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .below {
  z-index: 0;
}
.outer .top {
  z-index: 2;
}
.gradient {
  height: 20em;
  width: 20em;
  border-radius: 50%;
  position: absolute;
  filter: blur(50px);
  background: rgba(4, 246, 198, 0.4);
  margin-left: 30rem;
}

.gradient img {
  width: 40rem;
  height: 49rem;
  object-fit: cover;
  object-position: center;
}

.gradient::before {
  height: inherit;
  width: inherit;
  filter:blur(10px);
  background-image: linear-gradient(
    15deg,
    hsl(206,10%,76%),
    hsl(206,100%,50%),
    hsl(252,78%,60%),
    hsl(148,60%,60%),
    hsl(352,100%,62%)
  );
  position: absolute;
  top: 0;
  border-radius: 50%;
  -webkit-animation:move .6s ease-in-out both;animation:move 7s ease-in-out infinite both 
} 
.home-text {
  /* font-family: "Circular Spotify Tx T Light", sans-serif; */
  font-size: 32px;
  margin-top: 15rem;
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 2rem;
  z-index: 10;
  /* background-color: rgb(6, 44, 44); */
  position: relative; 
}
.home-text a {
  text-decoration: none;
  color: black;
}
.home-text h1{
  margin: 0rem;
  font-weight: bolder;
}
.home-text img {
  width: 70px;
  height: 70px;
  transform: rotate(8deg);
  opacity: 1;
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}
.second-section {
  text-align: center;
  margin-top: 7rem;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 83px 0 24px;
}
.second-section p {
  margin-top: 0rem;
  color: gray;
}
/* .card-list {
  list-style-type: none;
  justify-content: space-around;
} */
.card {
  display: inline-block;
  width: 38rem;
  text-align: center;
  cursor: pointer;
  height: 23rem;
  justify-content: space-between;
  /* margin-left: 5rem; */
  /* margin-top: 4rem; */
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.card:hover {
  transform: scale(1.04);
  box-shadow: 0px 0px 50px rgba(19, 170, 240, 0.5);
}
.column {
  float: left;
  width: 50%;
  padding: 15px 62px;
}
.card h3 {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 1.4rem;
  color: black;
}
.card img {
  width: 100%;
  height: 100%;
}
/* Remove extra left and right margins, due to padding */
.row {margin: 0 15px;}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.about-section {
  margin-left: 8rem;
  margin-bottom: 13rem;
  /* width: 65rem; */
  font-weight: 600;
}
.about {
  color: white;
}
.about-section h1 {
  margin-top: 13rem;
  font-size: 20px;
}
.about-section p {
  font-size: 39px;
}
.green {
  color: #1AE400;
}
.pink:hover {
  color: #FE549D;
  cursor: pointer;
  /* transition: 2s; */
  transition-duration: 0.7s;
}
.stackwork {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  background-color: #F8F9F9;
}
/* .skills{
  margin-top: 10rem;
  margin-left: 8rem;
  margin-bottom: 10rem;
} */
.liner {
  font-family: 'Londrina Outline', cursive;
  margin-top: 10rem;
  /* width: 60rem; */
  text-align: center;
  margin-bottom: 10rem;
  font-size: 96px;
  size: 96px;
  color: #8C8D8C;
  font-weight: 900;  
}
.bottom-bar {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  width: auto;
  margin-bottom: 8rem;
}
.bottom-bar h1 {
  font-size: 50px;
}
.education {
  margin-top: 4rem;
  margin-left: 15rem;
  margin-bottom: 10rem;
  width: 65rem;
  font-weight: 600;
  font-size: 24px;
}
.contact {
  margin-top: 4rem;
  margin-left: rem;
  display: inline;
  margin-bottom: 10rem;
  width: 65rem;
  font-weight: 600;
}
.contact h1 {
  margin-left: 2rem;
}
.contact a {
  text-decoration: none;
  color: black;
  margin-top: 2.5rem;
}
.socials {
    list-style-type: none;
}
.socials :hover {
  color: #1AE400;
  transition: 0.4s;
}
.grey {
  color: #8C8D8C;
}
/* saksham  */
.title {
  font-family: "Cairo";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  letter-spacing: 1px;
  line-height:2
}
.name {
  background-image: url(https://media.giphy.com/media/tj5v01sQumzCg/giphy.gif);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  /* text-transform: uppercase; */
  /* font-size: 120px; */
  margin: 10px 0;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 0rem;
  margin: 0rem;
}
.footer a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: 700;
}
/* .footer a:hover {
  color: #1AE400;
  transition: 0.6s;
} */
.right {
  margin-right: 45%;
}
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .bottom-bar {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    width: auto;
    margin-bottom: 8rem;
  }
  body {
    width: 600px;
  }
}